import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, Input, Radio, message, Select } from "antd";
import { PBox, PText, PLink, PButton, PH4 } from "../../theme/BaseTheme";
import { Loader } from "../../components/Loader";
import { refetchUser } from "../../../actions/auth";
import { generateTeeDesigns } from "../../../actions/tee";

class Control extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            prompt: "",
            isGenerating: false,
            shape: "circle",
        };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <PBox css={styles}>
                <Card title="Prompt">
                    <PBox>
                        <PText className="control-section-label">
                            Generate A Tee Design:
                        </PText>
                        <Select
                            style={{
                                width: "100%",
                            }}
                            options={[
                                { value: "circle", label: "Round" },
                                { value: "square", label: "Square" },
                            ]}
                            defaultValue={this.state.shape}
                            onChange={async (value) => {
                                this.setState({ shape: value });
                            }}
                        />
                        <PText className="control-section-label">Prompt:</PText>
                        <Input.TextArea
                            rows={12}
                            placeholder="Enter your prompt"
                            onChange={(e) => {
                                this.setState({ prompt: e.target.value });
                            }}
                        ></Input.TextArea>
                        <PText>
                            Example 1: A seascape, color blue, white background.
                        </PText>
                        <PText>
                            Example 2: With words "Have Sun, Have Fun", white
                            background.
                        </PText>
                        <PText>
                            <br />
                        </PText>
                    </PBox>

                    <PBox>
                        <Button
                            //className="control-section-button"
                            type="primary"
                            onClick={async () => {
                                if (
                                    this.props.userAccount?.credits <= 1 &&
                                    this.props.userAccount?.roll_over_credits <=
                                        1
                                ) {
                                    message.error(
                                        "Your credit balance is not enough. Please consider upgrading your plan or buying more credits.",
                                        3
                                    );
                                } else {
                                    if (this.state.prompt === "") {
                                        message.error(
                                            "Prompt cannot be empty. ",
                                            3
                                        );
                                    } else {
                                        await this.setState({
                                            isGenerating: true,
                                        });
                                        message.open({
                                            key: "gen_tee_loading",
                                            type: "loading",
                                            content:
                                                "Start generating designs. This can take a while.",
                                            duration: 0,
                                        });
                                        await this.props.generateTeeDesigns(
                                            this.state.shape,
                                            this.state.prompt
                                        );
                                        message.destroy("gen_tee_loading");
                                        await this.props.refetchUser();
                                        await this.setState({
                                            isGenerating: false,
                                        });
                                    }
                                }
                            }}
                            loading={this.state.isGenerating}
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            Generate{" "}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#000000"
                                viewBox="0 0 256 256"
                                style={{
                                    fill: "#d48806",
                                    backgroundColor: "#ffe58f",
                                    padding: "0.125rem 0.25rem",
                                    borderRadius: "0.25rem",
                                    width: "1rem",
                                    height: "1rem",
                                    marginLeft: "0.5rem",
                                    marginRight: "0.25rem",
                                }}
                            >
                                <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32,32,0,0,0,24,80V192a32,32,0,0,0,32,32H200a32,32,0,0,0,32-32V80A32,32,0,0,0,200,48Zm16,144a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V80A16,16,0,0,1,56,64H200a16,16,0,0,1,16,16Zm-52-56H92a28,28,0,0,0,0,56h72a28,28,0,0,0,0-56Zm-28,16v24H120V152ZM80,164a12,12,0,0,1,12-12h12v24H92A12,12,0,0,1,80,164Zm84,12H152V152h12a12,12,0,0,1,0,24ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm88,0a12,12,0,1,1,12,12A12,12,0,0,1,160,108Z"></path>
                            </svg>
                            x2
                        </Button>
                    </PBox>
                </Card>
            </PBox>
        );
    }
}

const styles = {
    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
    "& .control-section-label": {
        fontWeight: "600",
        fontSize: "14px",
        color: "$colors$text",
    },
    "& .control-section-button": {
        fontWeight: "600",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        color: "#fff",
        padding: "0.625rem 0.875rem",
        backgroundColor: "$colors$primary",
        borderRadius: "0.375rem",
        textDecoration: "none",
        marginRight: "2rem",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "$colors$primary500",
        },
    },
    "& .control-section-input-box": {
        margin: "1rem 0 2rem 0",
    },
};

function mapStateToProps(state) {
    return { userAccount: state.authReducer?.userAuth.userAccount };
}

export default connect(mapStateToProps, { generateTeeDesigns, refetchUser })(
    withRouter(Control)
);
