import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Input, Button, Form, Checkbox } from "antd";
import {
    PH2,
    PLink,
    PText,
    PBox,
    PHighlightButton,
    PImg,
} from "../../theme/BaseTheme";
import { AuthenticationLayout } from "./AuthenticationLayout";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { userSignIn } from "../../../actions/auth";

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
    }

    componentDidMount() {}

    onFinish = (values) => {
        console.log("Success:", values);
        this.props.userSignIn(values, this.props.history).then(() => {});
    };

    onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    render() {
        return (
            <AuthenticationLayout>
                <PBox css={styles}>
                    <PBox className="sign-in-title">
                        <PH2>Sign in to your account</PH2>
                    </PBox>
                    <PBox className="sign-in">
                        <Form
                            name="basic"
                            labelCol={{
                                span: 0,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            style={{
                                width: "100%",
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        type: "email",
                                        message:
                                            "${label} is not a valid email.",
                                    },
                                    {
                                        required: true,
                                        message: "Please enter your email.",
                                    },
                                ]}
                            >
                                <Input
                                    size="large"
                                    placeholder="Email"
                                    prefix={<MailOutlined />}
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your password.",
                                    },
                                ]}
                            >
                                <Input.Password
                                    size="large"
                                    placeholder="Password"
                                    prefix={<LockOutlined />}
                                />
                            </Form.Item>

                            <PBox
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                                className="remember-forgot-pwd-form"
                            >
                                <Form.Item
                                    name="remember"
                                    valuePropName="checked"
                                    wrapperCol={{
                                        offset: 0,
                                        span: 24,
                                    }}
                                >
                                    <Checkbox className="remember-me">
                                        Stay signed in for 7 days
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item>
                                    <PLink href="/forgot-password">
                                        Forgot your password?
                                    </PLink>
                                </Form.Item>
                            </PBox>
                            <Form.Item
                                wrapperCol={{
                                    span: 24,
                                }}
                            >
                                <PHighlightButton
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    className="sign-in-button"
                                >
                                    Log In
                                </PHighlightButton>
                            </Form.Item>
                        </Form>
                        <PText>- Or you can join with -</PText>
                        <PBox className="sign-in-button-group">
                            <a href="/api/auths/google">
                                <PBox className="sign-in-oauth-button">
                                    <PImg
                                        alt="patternful ai"
                                        src="/images/google-logo.png"
                                    />
                                    Continue with Google
                                </PBox>
                            </a>
                        </PBox>
                        <PBox className="agree-terms">
                            <PText>
                                By clicking Log In or continuing with Google,
                                you acknowledge you have read and agree to our{" "}
                                <a href="/terms" target="_blank">
                                    Terms and Conditions
                                </a>{" "}
                                and{" "}
                                <a href="/privacy" target="_blank">
                                    Privacy Policy
                                </a>
                                .
                            </PText>
                        </PBox>
                    </PBox>
                    <PBox className="sign-up-link">
                        <PText>
                            Don't have an account?{" "}
                            <PLink href="/sign-up">Create an account</PLink> now
                            to get <b>10 FREE credits</b>!
                        </PText>
                    </PBox>
                </PBox>
            </AuthenticationLayout>
        );
    }
}

const styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "& .sign-in": {
        width: "400px",
        marginTop: "2rem",
        backgroundColor: "#fff",
        padding: "3rem 2rem 2rem 2rem",
        borderRadius: "0.5rem",
        boxShadow:
            "0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
    },
    "& h2": {
        color: "$colors$text",
        textAlign: "center",
        margin: "20px 0",
        fontWeight: "600",
    },
    "& p": {
        color: "$colors$text",
        textAlign: "center",
        lineHeight: "24px",
        margin: "4px 0",
    },
    "& a": {
        color: "$colors$primary",
        fontWeight: "500",
        "&:hover": {
            color: "$colors$primary500",
        },
    },
    "& .sign-in-button": {
        color: "#fff",
        backgroundColor: "$colors$primary700",
        width: "100%",
        borderColor: "$colors$primary700",
        marginTop: "16px",
        borderRadius: "8px",
        "&:hover": {
            backgroundColor: "$colors$primary500",
            borderColor: "$colors$primary500",
        },
    },
    "& .sign-in-title": {
        fontSize: "18px",
        marginTop: "2rem",
    },
    "& .sign-in-button-group": {
        margin: "20px auto",
        width: "100%",
        "& a": {
            textDecoration: "none",
        },
    },
    "& .sign-in-oauth-button": {
        textAlign: "center",
        padding: "6px 0",
        borderRadius: "10px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid $colors$borderColor",
        width: "100%",
        textDecoration: "none",
        color: "$colors$text",
        marginBottom: "10px",
        fontSize: "14px",
        "& img": {
            width: "24px",
            height: "24px",
            marginRight: "10px",
        },
        "& svg": {
            fill: "#e1665d",
        },
        "&:hover": {
            backgroundColor: "$colors$primary50",
            borderColor: "$colors$primary50",
            "& svg": {
                fill: "#fff",
            },
        },
    },
    "& .remember-forgot-pwd-form": {
        "& .ant-form-item": {
            marginBottom: "4px",
        },
    },
    "& .agree-terms": {
        fontSize: "13px",
        "& p": { lineHeight: "1.6" },
        "& a": { textDecoration: "none" },
    },
    "& .sign-up-link": {
        marginTop: "2.5rem",
        fontSize: "16px",
        "& a": {
            color: "$colors$primary700",
            textDecoration: "none",
            fontWeight: "600",
            "&:hover": {
                color: "$colors$primary500",
            },
        },
    },
};

export default connect(null, { userSignIn })(withRouter(SignIn));
