import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { SellFaq } from "../../sections/SellFaq";
import SellPlan from "../../sections/SellPlan";

const faq_data = [
    {
        question: "What are the pricing options available on Patternful.ai?",
        answer: "Patternful.ai offers free, monthly subscription, annually subscription, and pay-as-you-go pricing options. The subscription options include starter, standard, and pro plans.",
    },
    {
        question:
            "What features are included in the free version of Patternful.ai?",
        answer: "The free version of Patternful.ai provides access to basic photo editing and design features. However, certain advanced features and premium assets may require credits. You can check the feature comparison on the pricing page. Credits can be bought through subscription plans or on a pay-as-you-go basis.",
    },
    {
        question:
            "Is there a trial period available for the Patternful.ai subscription?",
        answer: "Patternful.ai provides free, subscription-based, and pay-as-you-go plans. Currently, we do not have a trial period for our premium plan, but you can still explore Patternful.ai's features and benefits with our free plan. By creating an account on Patternful.ai, you'll receive FREE credits that allow you to access premium features.",
    },
    {
        question: "What payment methods does Patternful.ai accept?",
        answer: "Patternful.ai accepts credit and debit cards as payment methods.",
    },
    {
        question: "Can I upgrade or downgrade my subscription?",
        answer: "Yes, you can upgrade your subscription at any time and will pay the difference pro-rated. However, downgrades will take effect at the end of the billing cycle, and you will still be able to use your remaining credits until the end of the monthly billing cycle.",
    },
    {
        question: "Can I cancel my plan anytime?",
        answer: "Yes, you can cancel your plan at any time from your profile page by clicking manage my subscription. You will still have access until the end of your monthly billing period.",
    },
    {
        question: "Can I get a receipt for my subscription?",
        answer: "Yes, you can access your receipts and invoices from manage subscription page.",
    },
    {
        question: "What is the Community Gallery?",
        answer: "The Community Gallery is a place where paid members can explore patterns and designs being made on the platform.",
    },
    {
        question: "Can I keep my images private?",
        answer: "Your designs and uploaded images are always private, proprietary, and they remain your property. All your uploaded images are automatically deleted from our servers after a few hours. Images generated under Starter and Standard plan will show up in our Community Gallery. If you subscribe to the Pro plan, all your generated images will be private.",
    },
];

class Pricing extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>Pricing</title>
                    <meta
                        name="description"
                        content="Patternful.ai offers both free and premium pricing options. The premium options include starter, standard, and pro plans."
                    />
                    <meta
                        name="keywords"
                        content="Patternful, Patternful AI, AI Image Generation, AI Image Processing, AI Image Design, AI Image Editing, Seamless Pattern Generator, Mockup Generator, Background Remover, Binary Mask Generator, Image Upscaler, Digital Art, Graphic Design"
                    />
                </Helmet>
                <SellPlan />
                <SellFaq data={faq_data} />
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return { userAccount: state.authReducer?.userAuth.userAccount };
}

export default connect(mapStateToProps, {})(withRouter(Pricing));
