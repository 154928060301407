import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, Input, Radio, message, Select } from "antd";
import { PBox, PText, PLink, PButton, PH4 } from "../theme/BaseTheme";
import { Loader } from "../components/Loader";
import { generateImages } from "../../actions/pattern";
import { refetchUser } from "../../actions/auth";

const promptStyleOptions = [
    { value: "watercolor", label: "Watercolor" },
    { value: "illustration", label: "Illustration" },
    { value: "cartoon", label: "Cartoon" },
    { value: "impressionism", label: "Impressionism" },
    {
        value: "paper cut craft illustration",
        label: "Paper Cut Craft Illustration",
    },
    { value: "pastel", label: "Pastel" },
    { value: "stained glass window", label: "Stained Glass Window" },
    { value: "oil painting", label: "Oil Painting" },
    { value: "anime art", label: "Anime Art" },
    { value: "ukiyo-e", label: "Ukiyo-E" },
    { value: "pop-art", label: "Pop-Art" },
    { value: "art-deco", label: "Art-Deco" },
    { value: "mosaic illustration", label: "Mosaic Illustration" },
    { value: "abstract expressionism", label: "Abstract Expressionism" },
    { value: "bokeh", label: "Bokeh" },
    { value: "minimalist", label: "Minimalist" },
    { value: "flat design", label: "Flat Design" },
    { value: "art nouveau", label: "Art Nouveau" },
    { value: "morphism", label: "Morphism" },
    { value: "bohemian", label: "Bohemian" },
    { value: "dada", label: "Dada" },
    { value: "pixel art", label: "Pixel Art" },
    { value: "vector art", label: "Vector Art" },
];

const promptColorOptions = [
    { value: "ablaze colors", label: "Ablaze Colors" },
    { value: "aqua colors", label: "Aqua Colors" },
    { value: "ashy colors", label: "Ashy Colors" },
    { value: "beaming colors", label: "Beaming Colors" },
    { value: "beige colors", label: "Beige Colors" },
    { value: "black and white", label: "Black And White" },
    { value: "black", label: "Black" },
    { value: "blush colors", label: "Blush Colors" },
    { value: "bold colors", label: "Bold Colors" },
    { value: "brash colors", label: "Brash Colors" },
    { value: "bright colors", label: "Bright Colors" },
    { value: "brilliant colors", label: "Brilliant Colors" },
    { value: "candy colors", label: "Candy Colors" },
    { value: "cold colors", label: "Cold Colors" },
    { value: "cyan colors", label: "Cyan Colors" },
    { value: "danish pastel colors", label: "Danish Pastel Colors" },
    { value: "dark colors", label: "Dark Colors" },
    { value: "deep colors", label: "Deep Colors" },
    { value: "delicate colors", label: "Delicate Colors" },
    { value: "dim colors", label: "Dim Colors" },
    { value: "drab colors", label: "Drab Colors" },
    { value: "dusty colors", label: "Dusty Colors" },
    { value: "earth tones", label: "Earth Tones" },
    { value: "festive colors", label: "Festive Colors" },
    { value: "flaming colors", label: "Flaming Colors" },
    { value: "fluorescent colors", label: "Fluorescent Colors" },
    { value: "frost colors", label: "Frost Colors" },
    { value: "gradient colors", label: "Gradient Colors" },
    { value: "gummy colors", label: "Gummy  Colors" },
    { value: "jazzy colors", label: "Jazzy Colors" },
    { value: "light colors", label: "Light Colors" },
    { value: "muted colors", label: "Muted Colors" },
    { value: "neutral colors", label: "Neutral Colors" },
    { value: "opaque colors", label: "Opaque Colors" },
    { value: "pale colors", label: "Pale Colors" },
    { value: "pastel colors", label: "Pastel Colors" },
    { value: "prismatic colors", label: "Prismatic Colors" },
    { value: "radient colors", label: "Radient Colors" },
    { value: "retro colors", label: "Retro Colors" },
    { value: "rich colors", label: "Rich Colors" },
    { value: "spectrum colors", label: "Spectrum Colors" },
    { value: "sweet colors", label: "Sweet Colors" },
    { value: "tropical colors", label: "Tropical Colors" },
    { value: "vibrant colors", label: "Vibrant Colors" },
    { value: "vintage colors", label: "Vintage Colors" },
    { value: "vivid colors", label: "Vivid Colors" },
];

class Control extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            promptOption: "yop",
            prompt: "",
            isGenerating: false,
            promptObjects: "",
            promptColors: "",
            promptStyle: "",
        };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <PBox css={styles}>
                <Card title="Prompt">
                    <PBox>
                        <Radio.Group defaultValue="yop" buttonStyle="solid">
                            <Radio.Button
                                value="yop"
                                onClick={() => {
                                    this.setState({ promptOption: "yop" });
                                }}
                            >
                                Your Own Prompt
                            </Radio.Button>
                            <Radio.Button
                                value="gp"
                                onClick={() => {
                                    this.setState({ promptOption: "gp" });
                                }}
                            >
                                Prompt Builder
                            </Radio.Button>
                        </Radio.Group>
                    </PBox>
                    {this.state.promptOption === "yop" && (
                        <PBox className="control-section-input-box">
                            <Input.TextArea
                                rows={12}
                                placeholder="Enter your prompt"
                                onChange={(e) => {
                                    this.setState({ prompt: e.target.value });
                                }}
                            ></Input.TextArea>
                            <PText>Example 1: Flower, water color.</PText>
                            <PText>
                                Example 2: Pastel pattern with birthday gifts.
                            </PText>
                        </PBox>
                    )}
                    {this.state.promptOption === "gp" && (
                        <PBox>
                            <PText className="control-section-label">
                                Objects in the Pattern
                            </PText>
                            <Input.TextArea
                                rows={2}
                                onChange={(e) => {
                                    this.setState({
                                        promptObjects: e.target.value,
                                    });
                                }}
                            ></Input.TextArea>
                            <PText>Example: flower.</PText>
                            <PText className="control-section-label">
                                Colors
                            </PText>
                            <Select
                                style={{
                                    width: "100%",
                                }}
                                options={promptColorOptions}
                                onChange={async (value) => {
                                    this.setState({ promptColors: value });
                                }}
                            />
                            <PText className="control-section-label">
                                Design Style
                            </PText>
                            <Select
                                style={{
                                    width: "100%",
                                }}
                                options={promptStyleOptions}
                                onChange={async (value) => {
                                    this.setState({ promptStyle: value });
                                }}
                            />
                            <PText>
                                <br />
                            </PText>
                        </PBox>
                    )}
                    <PBox>
                        <Button
                            //className="control-section-button"
                            type="primary"
                            onClick={async () => {
                                if (
                                    this.props.userAccount?.credits <= 0 &&
                                    this.props.userAccount?.roll_over_credits <=
                                        0
                                ) {
                                    message.error(
                                        "Your credit balance is not enough. Please consider upgrading your plan or buying more credits.",
                                        3
                                    );
                                } else {
                                    var finalPrompt = this.state.prompt;
                                    if (this.state.promptOption === "gp") {
                                        finalPrompt =
                                            this.state.promptObjects +
                                            ", " +
                                            this.state.promptColors +
                                            ", " +
                                            this.state.promptStyle;
                                    }
                                    if (
                                        this.state.promptOption === "gp" &&
                                        this.state.promptObjects === ""
                                    ) {
                                        message.error(
                                            "Objects cannot be empty. ",
                                            3
                                        );
                                    } else if (finalPrompt === "") {
                                        message.error(
                                            "Prompt cannot be empty. ",
                                            3
                                        );
                                    } else {
                                        await this.setState({
                                            isGenerating: true,
                                        });
                                        message.open({
                                            key: "gen_pattern_loading",
                                            type: "loading",
                                            content:
                                                "Start generating patterns. This can take a while.",
                                            duration: 0,
                                        });
                                        await this.props.generateImages(
                                            finalPrompt
                                        );
                                        message.destroy("gen_pattern_loading");
                                        await this.props.refetchUser();
                                        await this.setState({
                                            isGenerating: false,
                                        });
                                    }
                                }
                            }}
                            loading={this.state.isGenerating}
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            Generate{" "}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#000000"
                                viewBox="0 0 256 256"
                                style={{
                                    fill: "#d48806",
                                    backgroundColor: "#ffe58f",
                                    padding: "0.125rem 0.25rem",
                                    borderRadius: "0.25rem",
                                    width: "1rem",
                                    height: "1rem",
                                    marginLeft: "0.5rem",
                                }}
                            >
                                <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32,32,0,0,0,24,80V192a32,32,0,0,0,32,32H200a32,32,0,0,0,32-32V80A32,32,0,0,0,200,48Zm16,144a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V80A16,16,0,0,1,56,64H200a16,16,0,0,1,16,16Zm-52-56H92a28,28,0,0,0,0,56h72a28,28,0,0,0,0-56Zm-28,16v24H120V152ZM80,164a12,12,0,0,1,12-12h12v24H92A12,12,0,0,1,80,164Zm84,12H152V152h12a12,12,0,0,1,0,24ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm88,0a12,12,0,1,1,12,12A12,12,0,0,1,160,108Z"></path>
                            </svg>
                        </Button>
                    </PBox>
                </Card>
            </PBox>
        );
    }
}

const styles = {
    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
    "& .control-section-label": {
        fontWeight: "600",
        fontSize: "14px",
        color: "$colors$text",
    },
    "& .control-section-button": {
        fontWeight: "600",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        color: "#fff",
        padding: "0.625rem 0.875rem",
        backgroundColor: "$colors$primary",
        borderRadius: "0.375rem",
        textDecoration: "none",
        marginRight: "2rem",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "$colors$primary500",
        },
    },
    "& .control-section-input-box": {
        margin: "1rem 0 2rem 0",
    },
};

function mapStateToProps(state) {
    return { userAccount: state.authReducer?.userAuth.userAccount };
}

export default connect(mapStateToProps, { generateImages, refetchUser })(
    withRouter(Control)
);
