import axios from "axios";
import { message } from "antd";

export const createSubscription = (plan) => async (dispatch) => {
    await axios
        .post(`/api/subscriptions/create/${plan}`)
        .then((response) => {
            dispatch({
                type: "CREATE_SUBSCRIPTION_SUCCESS",
                payload: response.data,
            });
            if (response.data.redirect) {
                window.open(response.data.redirect, "_blank").focus();
            }
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const createPayAsYouGo = (plan) => async (dispatch) => {
    await axios
        .post(`/api/subscriptions/pay-as-you-go/${plan}`)
        .then((response) => {
            dispatch({
                type: "CREATE_PAY_AS_YOU_GO_SUCCESS",
                payload: response.data,
            });
            if (response.data.redirect) {
                window.open(response.data.redirect, "_blank").focus();
            }
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const fetchSubscription = () => async (dispatch) => {
    await axios
        .post(`/api/subscriptions/fetch`)
        .then((response) => {
            dispatch({
                type: "FETCH_SUBSCRIPTION_SUCCESS",
                payload: response.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const cancelSubscription = () => async (dispatch) => {
    await axios
        .post(`/api/subscriptions/cancel`)
        .then((response) => {
            message.success(response.data.message);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const editBilling = () => async (dispatch) => {
    await axios
        .post(`/api/subscriptions/edit-billing`)
        .then((response) => {
            if (response.data.redirect) {
                console.log(response.data.redirect);
                window.open(response.data.redirect, "_blank").focus();
            }
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const updateSubscription = (plan) => async (dispatch) => {
    await axios
        .post(`/api/subscriptions/update/${plan}`)
        .then((response) => {
            message.success(response.data.message);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

export const previewProration = (plan) => async (dispatch) => {
    await axios
        .get(`/api/subscriptions/preview-proration/${plan}`)
        .then((response) => {
            dispatch({
                type: "PREVIEW_PRORATION_SUCCESS",
                payload: response.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};
