import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Input, Pagination } from "antd";
import {
    TwitterShareButton,
    XIcon,
    PinterestShareButton,
    PinterestIcon,
} from "react-share";
import { PBox, PH1, PImg, PLink, PText, PH4 } from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { fetchSearchPatterns } from "../../../actions/pattern";

class PatternGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            searchKey: "",
            page: parseInt(this.props.match.params.id),
            pageSize: 20,
            urlParams: new URLSearchParams(window.location.search),
            resultKey: "",
        };
    }
    async componentDidMount() {
        await this.props.fetchSearchPatterns(
            this.state.urlParams,
            (this.state.page - 1) * this.state.pageSize,
            this.state.pageSize
        );
        await this.setState({
            searchKey: this.state.urlParams.get("key"),
            resultKey:
                "search_patterns" + "_" + this.state.urlParams.toString(),
        });
        await this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>Pattern Gallery</title>
                    <meta
                        name="description"
                        content="Showcase seamless patterns generated by Patternful.ai. Whether you're looking to enhance textiles, digital content, or print media, generated patterns ensure your work stands out with a unique and professional aesthetic."
                    />
                    <meta
                        name="keywords"
                        content="Seamless Pattern Generator, Seamless Pattern AI, Seamless Pattern Maker, Seamless Pattern APP, Seamless Pattern Online Generator, Print On Demand, Pattern Design, Patternful, Patternful AI"
                    />
                </Helmet>
                <PBox css={styles}>
                    <PBox className="pattern-gallery-title">
                        <PH1>Pattern Gallery</PH1>
                        {!this.props.userAccount && (
                            <>
                                <PText>
                                    Try our{" "}
                                    <a href="/seamless-pattern/app">
                                        Seamless Pattern Generator
                                    </a>{" "}
                                    to create your own unique and beautiful
                                    patterns.{" "}
                                    <a href="/sign-up" target="_blank">
                                        Sign up
                                    </a>{" "}
                                    to get <b>10 FREE credits</b>!
                                </PText>
                                <PText>
                                    Subscribe to one of our{" "}
                                    <a href="/pricing" target="_blank">
                                        plans
                                    </a>{" "}
                                    to get full access to our Community Gallery.
                                </PText>
                            </>
                        )}
                        {this.props.userAccount &&
                            this.props.userAccount.plan == "free" && (
                                <PText>
                                    Subscribe to one of our{" "}
                                    <a href="/pricing" target="_blank">
                                        plans
                                    </a>{" "}
                                    to get full access to our Community Gallery.
                                </PText>
                            )}
                        {this.props.userAccount &&
                            this.props.userAccount.plan != "free" && (
                                <PText>
                                    <b>Update</b>: Only paid members can view
                                    our Community Gallery. Upgrade to{" "}
                                    <a href="/pricing">Pro</a> plan to keep your
                                    generations private.
                                </PText>
                            )}
                    </PBox>

                    {this.props.userAccount &&
                        this.props.userAccount.plan != "free" && (
                            <PBox className="pattern-gallery-search">
                                <Input.Search
                                    placeholder="Search for patterns"
                                    onSearch={(value) => {
                                        window.location.href = `/pattern-gallery/1?key=${value}`;
                                    }}
                                />
                            </PBox>
                        )}
                    {this.props.userAccount &&
                        this.props.userAccount.plan != "free" &&
                        this.props.searchResults[this.state.resultKey]
                            ?.totalSearchPatterns !== 0 && (
                            <PH4>Search results for {this.state.searchKey}</PH4>
                        )}
                    {this.props.searchResults[this.state.resultKey]
                        ?.totalSearchPatterns !== 0 && (
                        <PBox className="pattern-gallery-container">
                            {this.props.searchResults[
                                this.state.resultKey
                            ]?.searchPatterns?.map((pattern, idx) => {
                                return (
                                    <PBox className="pattern-gallery-image">
                                        <PImg
                                            crossorigin={
                                                process.env.NODE_ENV ===
                                                "development"
                                                    ? "anonymous"
                                                    : ""
                                            }
                                            src={pattern.src}
                                            alt={
                                                "patternful ai" +
                                                    pattern.prompt !==
                                                "hidden"
                                                    ? pattern.prompt
                                                    : ""
                                            }
                                        />
                                        <PBox className="pattern-gallery-share-buttons">
                                            <TwitterShareButton
                                                url={String(
                                                    window.location.origin
                                                )}
                                                title={`${
                                                    pattern.prompt !== "hidden"
                                                        ? pattern.prompt
                                                        : ""
                                                }`}
                                                hashtags={[
                                                    "PatternfulAI",
                                                    "Phair",
                                                    "DesignInspiration",
                                                    "SeamlessPaper",
                                                    "SeamlessPattern",
                                                    "Pattern",
                                                    "PatternDesign",
                                                    "Wallpaper",
                                                    "Background",
                                                    "PrintablePaper",
                                                    "DigitalArt",
                                                    "DigitalPaper",
                                                ]}
                                            >
                                                <XIcon size={32} round />
                                            </TwitterShareButton>
                                            <PinterestShareButton
                                                url={String(
                                                    window.location.origin
                                                )}
                                                media={pattern.src}
                                                description={
                                                    pattern.prompt !== "hidden"
                                                        ? pattern.prompt
                                                        : ""
                                                }
                                            >
                                                <PinterestIcon
                                                    size={32}
                                                    round
                                                />
                                            </PinterestShareButton>
                                        </PBox>
                                        <PBox className="pattern-gallery-prompt">
                                            <PText>
                                                Prompt: {pattern.prompt}
                                            </PText>
                                        </PBox>
                                    </PBox>
                                );
                            })}
                        </PBox>
                    )}
                    {this.props.searchResults[this.state.resultKey]
                        ?.totalSearchPatterns === 0 && (
                        <PH4>No Results Found.</PH4>
                    )}
                    {this.props.searchResults[this.state.resultKey]
                        ?.totalSearchPatterns !== 0 && (
                        <PBox className="pattern-gallery-pagination">
                            <Pagination
                                defaultCurrent={this.state.page}
                                total={parseInt(
                                    this.props.searchResults[
                                        this.state.resultKey
                                    ]?.totalSearchPatterns
                                )}
                                pageSize={this.state.pageSize}
                                showSizeChanger={false}
                                showQuickJumper={true}
                                onChange={async (page) => {
                                    window.location.href = `/pattern-gallery/${page}?${this.state.urlParams.toString()}`;
                                }}
                            />
                        </PBox>
                    )}
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    padding: "0 1.5rem",
    maxWidth: "80rem",
    margin: "2rem auto",
    "@bp2": { marginTop: "4rem" },
    "@bp4": { paddingLeft: "2rem", paddingRight: "2rem" },
    "& h1": { textAlign: "center", marginBottom: "2rem" },
    "& .pattern-gallery-search": {
        marginBottom: "3rem",
    },
    "& .pattern-gallery-container": {
        margin: "0 auto 6rem auto",
        display: "grid",
        gap: "1rem",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        "@bp2": { gridTemplateColumns: "repeat(3, minmax(0, 1fr))" },
        "@bp4": { gridTemplateColumns: "repeat(4, minmax(0, 1fr))" },
    },
    "& .pattern-gallery-title": {
        textAlign: "center",
        marginBottom: "3rem",
        "& a": {
            textDecoration: "none",
            color: "$colors$primary",
            fontWeight: "500",
        },
    },
    "& .pattern-gallery-image": {
        position: "relative",
        overflow: "hidden",
        border: "1px solid $colors$borderColor",
        borderRadius: "0.5rem",
        "& img": {
            width: "100%",
            "-webkit-transition": "all .5s ease",
            "-moz-transition": "all .5s ease",
            transition: "all .5s ease",
            display: "block",
        },
        "& .pattern-gallery-share-buttons": {
            position: "absolute",
            left: "1rem",
            top: "1rem",
            "& button": { marginRight: "0.5rem" },
            opacity: "0",
            visibility: "hidden",
            "-webkit-transition": "all .5s ease",
            "-moz-transition": "all .5s ease",
            transition: "all .5s ease",
        },
        "& .pattern-gallery-external-link": {
            position: "absolute",
            right: "1rem",
            top: "1rem",
            opacity: "0",
            visibility: "hidden",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            borderRadius: "50%",
            padding: "0.3rem",
            "-webkit-transition": "all .5s ease",
            "-moz-transition": "all .5s ease",
            transition: "all .5s ease",
            "& svg": { fill: "#fff" },
        },
        "& .pattern-gallery-prompt": {
            position: "absolute",
            left: "0",
            bottom: "0",
            padding: "0 1rem",
            margin: "0",
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            opacity: "0",
            visibility: "hidden",
            "-webkit-transition": "all .5s ease",
            "-moz-transition": "all .5s ease",
            transition: "all .5s ease",
            "& p": {
                "-webkit-line-clamp": "2",
                "-webkit-box-orient": "vertical",
                overflow: "hidden",
                display: "-webkit-box",
            },
        },
    },
    "& .pattern-gallery-image:hover": {
        "& img": { transform: "scale(1.1)" },
        "& .pattern-gallery-share-buttons": {
            opacity: "1",
            visibility: "visible",
        },
        "& .pattern-gallery-external-link": {
            opacity: "1",
            visibility: "visible",
        },
        "& .pattern-gallery-prompt": { opacity: "1", visibility: "visible" },
    },
    "& .pattern-gallery-pagination": {
        display: "flex",
        justifyContent: "center",
        marginBottom: "6rem",
    },
};

function mapStateToProps(state) {
    return {
        userAccount: state.authReducer?.userAuth?.userAccount,
        searchResults: state.patternReducer?.fetchSearchPatterns,
    };
}

export default connect(mapStateToProps, { fetchSearchPatterns })(
    withRouter(PatternGallery)
);
