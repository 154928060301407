import { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal, Card, Space, Button } from "antd";
import { PText, PH4, PBox } from "../../theme/BaseTheme";
import {
    ProPlanFeatures,
    StandardPlanFeatures,
    StarterPlanFeatures,
} from "./PlanFeatures";
import {
    createSubscription,
    updateSubscription,
} from "../../../actions/subscription";
import { refetchUser } from "../../../actions/auth";

export const ChangePlanModal = (props) => {
    const [planInterval, setPlanInterval] = useState("monthly");

    return (
        <Modal
            title={"Change Your Subscription"}
            open={props.isChangePlanModalOpen}
            onOk={async () => {
                props.handleChangePlanModalOk();
            }}
            onCancel={props.handleChangePlanModalCancel}
            okText={"Change"}
            footer={null}
            width={1200}
        >
            <PBox css={styles}>
                <PBox className="change-plan-modal-switch">
                    <Button
                        type={"primary"}
                        ghost={planInterval === "monthly" ? false : true}
                        onClick={() => {
                            setPlanInterval("monthly");
                        }}
                    >
                        Monthly
                    </Button>
                    <Button
                        type={"primary"}
                        ghost={planInterval === "annually" ? false : true}
                        onClick={() => {
                            setPlanInterval("annually");
                        }}
                    >
                        Annually (one months free)
                    </Button>
                </PBox>
                <PBox className="change-plan-modal-plans-container">
                    <Card
                        hoverable={true}
                        title={
                            planInterval === "monthly"
                                ? "Starter $10/month"
                                : "Starter $110/month"
                        }
                        extra={
                            <Button
                                type="primary"
                                ghost
                                onClick={async () => {
                                    if (planInterval === "monthly") {
                                        await props.setNewPlan(
                                            "starter_monthly"
                                        );
                                    } else {
                                        await props.setNewPlan(
                                            "starter_annually"
                                        );
                                    }

                                    await props.handleChangePlanModalOk();
                                }}
                            >
                                Subscribe
                            </Button>
                        }
                        style={{
                            width: 360,
                        }}
                    >
                        <StarterPlanFeatures highlight={true} />
                    </Card>
                    <Card
                        hoverable={true}
                        title={
                            planInterval === "monthly"
                                ? "Standard $20/month"
                                : "Standard $220/month"
                        }
                        extra={
                            <Button
                                type="primary"
                                ghost
                                onClick={async () => {
                                    if (planInterval === "monthly") {
                                        await props.setNewPlan(
                                            "standard_monthly"
                                        );
                                    } else {
                                        await props.setNewPlan(
                                            "standard_annually"
                                        );
                                    }
                                    await props.handleChangePlanModalOk();
                                }}
                            >
                                Subscribe
                            </Button>
                        }
                        style={{
                            width: 360,
                        }}
                    >
                        <StandardPlanFeatures highlight={true} />
                    </Card>
                    <Card
                        hoverable={true}
                        title={
                            planInterval === "monthly"
                                ? "Pro $40/month"
                                : "Pro $440/month"
                        }
                        extra={
                            <Button
                                type="primary"
                                ghost
                                onClick={async () => {
                                    if (planInterval === "monthly") {
                                        await props.setNewPlan("pro_monthly");
                                    } else {
                                        await props.setNewPlan("pro_annually");
                                    }
                                    await props.handleChangePlanModalOk();
                                }}
                            >
                                Subscribe
                            </Button>
                        }
                        style={{
                            width: 360,
                        }}
                    >
                        <ProPlanFeatures highlight={true} />
                    </Card>
                </PBox>
                <PText>
                    <br />
                </PText>
            </PBox>
        </Modal>
    );
};

const styles = {
    "& .change-plan-modal-switch": {
        display: "flex",
        justifyContent: "center",
        marginTop: "1rem",
        marginBottom: "1rem",
        "& button": {
            marginRight: "0.25rem",
        },
    },
    "& .change-plan-modal-plans-container": {
        margin: "0 auto",
        display: "grid",
        gap: "1rem",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        "@bp2": {
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        },
        "@bp4": {
            gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
        },
        "& .ant-card": {
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    "& .sell-plan-card-list": {
        color: "rgb(75 85 99)",
        lineHeight: "1.75rem",
        fontSize: "0.875rem",
        margin: "0 0 0 0",
        padding: "0",
        listStyle: "none",
        "& li": {
            columnGap: "0.75rem",
            display: "flex",
        },
        "& svg": {
            color: "$colors$primary",
            width: "1.25rem",
            height: "1.75rem",
        },
        "& p": {
            margin: "0",
            padding: "0",
        },
    },
};

function mapStateToProps(state) {
    return { userAccount: state.authReducer?.userAuth.userAccount };
}

export default connect(mapStateToProps, {
    createSubscription,
    updateSubscription,
    refetchUser,
})(withRouter(ChangePlanModal));
