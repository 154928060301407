import { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal, Card, Space, Button, Radio, Divider } from "antd";
import { PText, PH4, PBox, PSpan } from "../../theme/BaseTheme";
import { PayAsYouGoPlanFeatures } from "./PlanFeatures";
import { createPayAsYouGo } from "../../../actions/subscription";
import { refetchUser } from "../../../actions/auth";

export const BuyCreditsModal = (props) => {
    const [oneTimePlan, setOneTimePlan] = useState("pay_as_you_go_20");

    return (
        <Modal
            title={"Buy More Credits"}
            open={props.isBuyCreditsModalOpen}
            onOk={async () => {
                await props.createPayAsYouGo(oneTimePlan);
                props.handleBuyCreditsModalOk();
            }}
            onCancel={props.handleBuyCreditsModalCancel}
            okText={"Buy Credits"}
            width={420}
        >
            <PBox css={styles}>
                <PBox className="change-plan-modal-plans-container">
                    <Radio.Group
                        onChange={(e) => {
                            setOneTimePlan(e.target.value);
                        }}
                        value={oneTimePlan}
                    >
                        <Space direction="vertical">
                            <Radio value={"pay_as_you_go_5"}>
                                <PSpan>$5</PSpan> for 25 credits
                            </Radio>
                            <Radio value={"pay_as_you_go_10"}>
                                <PSpan>$10</PSpan> for 50 credits
                            </Radio>
                            <Radio value={"pay_as_you_go_20"}>
                                <PSpan>$20</PSpan> for 100 credits
                            </Radio>
                            <Radio value={"pay_as_you_go_40"}>
                                <PSpan>$40</PSpan> for 200 credits
                            </Radio>
                            <Radio value={"pay_as_you_go_100"}>
                                <PSpan>$100</PSpan> for 500 credits
                            </Radio>
                            <Radio value={"pay_as_you_go_160"}>
                                <PSpan>$160</PSpan> for 800 credits
                            </Radio>
                            <Radio value={"pay_as_you_go_200"}>
                                <PSpan>$200</PSpan> for 1000 credits
                            </Radio>
                            <Radio value={"pay_as_you_go_400"}>
                                <PSpan>$400</PSpan> for 2000 credits
                            </Radio>
                        </Space>
                    </Radio.Group>
                    <Divider>with</Divider>
                    <PayAsYouGoPlanFeatures credits={100} />
                </PBox>
            </PBox>
        </Modal>
    );
};

const styles = {
    "& .change-plan-modal-switch": {
        display: "flex",
        justifyContent: "center",
        marginTop: "1rem",
        marginBottom: "1rem",
        "& button": {
            marginRight: "0.25rem",
        },
    },
    "& .change-plan-modal-plans-container": {
        margin: "1rem auto 0 auto",
    },
    "& .sell-plan-card-list": {
        color: "rgb(75 85 99)",
        lineHeight: "1.75rem",
        fontSize: "0.875rem",
        margin: "0 0 0 0",
        padding: "0",
        listStyle: "none",
        "& li": {
            columnGap: "0.75rem",
            display: "flex",
        },
        "& svg": {
            color: "$colors$primary",
            width: "1.25rem",
            height: "1.75rem",
        },
        "& p": {
            margin: "0",
            padding: "0",
        },
    },
};

function mapStateToProps(state) {
    return { userAccount: state.authReducer?.userAuth.userAccount };
}

export default connect(mapStateToProps, {
    createPayAsYouGo,
    refetchUser,
})(withRouter(BuyCreditsModal));
