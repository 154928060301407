import { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, Divider, Radio, Space } from "antd";
import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PText,
    PSpan,
    PH4,
} from "../theme/BaseTheme";
import {
    createPayAsYouGo,
    createSubscription,
} from "../../actions/subscription";
import {
    CommonFeatures,
    PayAsYouGoPlanFeatures,
    ProPlanFeaturesExtra,
    StandardPlanFeaturesExtra,
    StarterPlanFeaturesExtra,
} from "../components/app/PlanFeatures";

const SellPlan = (props) => {
    const [monthlyPlan, setMonthlyPlan] = useState("standard_monthly");
    const [annualPlan, setAnnualPlan] = useState("standard_annually");
    const [oneTimePlan, setOneTimePlan] = useState("pay_as_you_go_20");

    return (
        <PSection css={styles}>
            <PBox className="sell-plan">
                <PContainer>
                    <PText className="sell-plan-subtitle">Pricing</PText>
                    <PH1 className="sell-plan-title">Subscription Plans</PH1>

                    <PBox className="sell-plan-cards">
                        <Card hoverable={true} className="sell-plan-card">
                            <PH4>Monthly Plan</PH4>
                            <>
                                <Radio.Group
                                    onChange={(e) => {
                                        setMonthlyPlan(e.target.value);
                                    }}
                                    value={monthlyPlan}
                                >
                                    <Space direction="vertical">
                                        <Radio value={"starter_monthly"}>
                                            <PText className="sell-plan-card-price">
                                                <PSpan>$10</PSpan> / month for
                                                starter plan
                                            </PText>
                                        </Radio>
                                        <StarterPlanFeaturesExtra />
                                        <Radio value={"standard_monthly"}>
                                            <PText className="sell-plan-card-price">
                                                <PSpan>$20</PSpan> / month for
                                                standard plan
                                            </PText>
                                        </Radio>
                                        <StandardPlanFeaturesExtra />
                                        <Radio value={"pro_monthly"}>
                                            <PText className="sell-plan-card-price">
                                                <PSpan>$40</PSpan> / month for
                                                pro plan
                                            </PText>
                                        </Radio>
                                        <ProPlanFeaturesExtra />
                                    </Space>
                                </Radio.Group>
                            </>

                            <Divider>with</Divider>
                            <CommonFeatures highlight={true} />
                            <Button
                                type="primary"
                                ghost
                                onClick={async () => {
                                    if (props.isLoggedIn) {
                                        await props.createSubscription(
                                            monthlyPlan
                                        );
                                    } else {
                                        window
                                            .open("/sign-in", "_blank")
                                            .focus();
                                    }
                                }}
                            >
                                Buy Plan
                            </Button>
                        </Card>
                        <Card hoverable={true} className="sell-plan-card">
                            <PH4>Annual Plan (One Month Free)</PH4>

                            <>
                                <Radio.Group
                                    onChange={(e) => {
                                        setAnnualPlan(e.target.value);
                                    }}
                                    value={annualPlan}
                                >
                                    <Space direction="vertical">
                                        <Radio value={"starter_annually"}>
                                            <PText className="sell-plan-card-price">
                                                <PSpan>$110</PSpan> / year for
                                                starter plan
                                            </PText>
                                        </Radio>
                                        <StarterPlanFeaturesExtra />
                                        <Radio value={"standard_annually"}>
                                            <PText className="sell-plan-card-price">
                                                <PSpan>$220</PSpan> / year for
                                                standard plan
                                            </PText>
                                        </Radio>
                                        <StandardPlanFeaturesExtra />
                                        <Radio value={"pro_annually"}>
                                            <PText className="sell-plan-card-price">
                                                <PSpan>$440</PSpan> / year for
                                                pro plan
                                            </PText>
                                        </Radio>
                                        <ProPlanFeaturesExtra />
                                    </Space>
                                </Radio.Group>
                                <Divider>with</Divider>
                                <CommonFeatures />
                                <Button
                                    type="primary"
                                    ghost
                                    onClick={async () => {
                                        if (props.isLoggedIn) {
                                            await props.createSubscription(
                                                annualPlan
                                            );
                                        } else {
                                            window
                                                .open("/sign-in", "_blank")
                                                .focus();
                                        }
                                    }}
                                >
                                    Buy Plan
                                </Button>
                            </>
                        </Card>
                        <Card hoverable={true} className={"sell-plan-card"}>
                            <PH4>Pay As You Go</PH4>
                            <>
                                <Radio.Group
                                    onChange={(e) => {
                                        setOneTimePlan(e.target.value);
                                    }}
                                    value={oneTimePlan}
                                >
                                    <Space direction="vertical">
                                        <Radio value={"pay_as_you_go_5"}>
                                            <PText className="sell-plan-card-price">
                                                <PSpan>$5</PSpan> for 25 credits
                                                never expire
                                            </PText>
                                        </Radio>
                                        <Radio value={"pay_as_you_go_10"}>
                                            <PText className="sell-plan-card-price">
                                                <PSpan>$10</PSpan> for 50
                                                credits never expire
                                            </PText>
                                        </Radio>
                                        <Radio value={"pay_as_you_go_20"}>
                                            <PText className="sell-plan-card-price">
                                                <PSpan>$20</PSpan> for 100
                                                credits never expire
                                            </PText>
                                        </Radio>
                                        <Radio value={"pay_as_you_go_40"}>
                                            <PText className="sell-plan-card-price">
                                                <PSpan>$40</PSpan> for 200
                                                credits never expire
                                            </PText>
                                        </Radio>
                                        <Radio value={"pay_as_you_go_100"}>
                                            <PText className="sell-plan-card-price">
                                                <PSpan>$100</PSpan> for 500
                                                credits never expire
                                            </PText>
                                        </Radio>
                                        <Radio value={"pay_as_you_go_160"}>
                                            <PText className="sell-plan-card-price">
                                                <PSpan>$160</PSpan> for 800
                                                credits never expire
                                            </PText>
                                        </Radio>
                                        <Radio value={"pay_as_you_go_200"}>
                                            <PText className="sell-plan-card-price">
                                                <PSpan>$200</PSpan> for 1000
                                                credits never expire
                                            </PText>
                                        </Radio>
                                        <Radio value={"pay_as_you_go_400"}>
                                            <PText className="sell-plan-card-price">
                                                <PSpan>$400</PSpan> for 2000
                                                credits never expire
                                            </PText>
                                        </Radio>
                                    </Space>
                                </Radio.Group>
                            </>
                            <Divider>with</Divider>
                            <PayAsYouGoPlanFeatures credits={100} />
                            <Button
                                type="primary"
                                ghost
                                onClick={async () => {
                                    if (props.isLoggedIn) {
                                        await props.createPayAsYouGo(
                                            oneTimePlan
                                        );
                                    } else {
                                        window
                                            .open("/sign-in", "_blank")
                                            .focus();
                                    }
                                }}
                            >
                                Buy Credits
                            </Button>
                        </Card>
                    </PBox>
                </PContainer>
            </PBox>
        </PSection>
    );
};

const styles = {
    marginTop: "80px",
    "& .sell-plan": { marginBottom: "1rem" },
    "& .sell-plan-table": {},
    "& .sell-plan-container": {
        position: "relative",
    },
    "& .sell-plan-subtitle": {
        textAlign: "center",
        color: "$colors$primary",
        lineHeight: "1.75rem",
        fontWeight: "600",
        fontSize: "1rem",
        margin: "0",
    },
    "& .sell-plan-title": {
        textAlign: "center",
        fontSize: "2.5rem",
        lineHeight: "1",
        color: "rgb(17 24 39)",
        letterSpacing: "-0.025em",
        fontWeight: "700",
        margin: "0.75rem 0 0 0",
    },
    "& .sell-plan-switch": {
        display: "flex",
        justifyContent: "center",
        marginTop: "3rem",
        "& button": {
            marginRight: "0.25rem",
        },
    },
    "& .sell-plan-coming-soon": {
        textAlign: "center",
        color: "rgb(75 85 99)",
        lineHeight: "2rem",
        fontSize: "1.125rem",
        maxWidth: "42rem",
        margin: "1.5rem auto",
    },
    "& .sell-plan-cards": {
        display: "grid",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        gap: "2rem",
        maxWidth: "28rem",
        marginTop: "4.5rem",
        marginLeft: "auto",
        marginRight: "auto",
        "@bp4": {
            gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
            maxWidth: "none",
        },
    },
    "& .sell-plan-card": {
        padding: "0.5rem",
        borderRadius: "1.5rem",
        border: "1px solid $colors$borderColor",
        "& h4": {
            fontWeight: "600",
            fontSize: "1.125rem",
            marginTop: "0.25rem",
        },
        "& .sell-plan-card-desc": {
            color: "rgb(75 85 99)",
            lineHeight: "1.5rem",
            fontSize: "0.875rem",
            margin: "1rem 0 0 0",
        },
        "& .sell-plan-card-price": {
            columnGap: "0.25rem",
            alignItems: "baseline",
            display: "flex",
            margin: "0.0625rem 0",
            color: "rgb(75 85 99)",
            lineHeight: "1.5rem",
            fontWeight: "600",
            fontSize: "0.875rem",
            "& span": {
                color: "rgb(17 24 39)",
                fontWeight: "700",
                fontSize: "1.5rem",
                lineHeight: "2.5rem",
                letterSpacing: "-0.025rem",
            },
        },
        "& button": {
            width: "100%",
            marginTop: "1.5rem",
        },
        "& a": {
            border: "1px solid $colors$borderColor",
            color: "$colors$primary",
            lineHeight: "1.5rem",
            textAlign: "center",
            padding: "0.5rem 0.75rem",
            fontSize: "0.875rem",
            borderRadius: "0.375rem",
            marginTop: "1.5rem",
            display: "block",
            cursor: "pointer",
        },
        "& ul": {
            color: "rgb(75 85 99)",
            lineHeight: "1.75rem",
            fontSize: "0.875rem",
            margin: "0 0 0 0",
            padding: "0 0 0 0",
            listStyle: "none",
            "& li": {
                columnGap: "0.75rem",
                display: "flex",
            },
            "& svg": {
                color: "$colors$primary",
                width: "1.25rem",
                height: "1.75rem",
            },
            "& p": {
                margin: "0",
                padding: "0",
            },
        },
    },
    "& .sell-plan-card-highlight": {
        padding: "0.5rem",
        borderRadius: "1.5rem",
        border: "2px solid $colors$primary",
        "& h4": {
            fontWeight: "600",
            fontSize: "1.125rem",
            marginTop: "0.25rem",
        },
        "& .sell-plan-card-desc": {
            color: "rgb(75 85 99)",
            lineHeight: "1.5rem",
            fontSize: "0.875rem",
            margin: "1rem 0 0 0",
        },
        "& .sell-plan-card-price": {
            columnGap: "0.25rem",
            alignItems: "baseline",
            display: "flex",
            margin: "1.5rem 0 0 0",
            color: "rgb(75 85 99)",
            lineHeight: "1.5rem",
            fontWeight: "600",
            fontSize: "0.875rem",
            "& span": {
                color: "rgb(17 24 39)",
                fontWeight: "700",
                fontSize: "2.25rem",
                lineHeight: "2.5rem",
                letterSpacing: "-0.025rem",
            },
        },
        "& button": {
            width: "100%",
            marginTop: "1.5rem",
        },
        "& a": {
            border: "1px solid $colors$primary",
            color: "#fff",
            backgroundColor: "$colors$primary",
            lineHeight: "1.5rem",
            textAlign: "center",
            padding: "0.5rem 0.75rem",
            fontSize: "0.875rem",
            borderRadius: "0.375rem",
            marginTop: "1.5rem",
            display: "block",
            cursor: "pointer",
        },
        "& ul": {
            color: "rgb(75 85 99)",
            lineHeight: "1.75rem",
            fontSize: "0.875rem",
            margin: "2rem 0 0 0",
            padding: "0",
            listStyle: "none",
            "& li": {
                columnGap: "0.75rem",
                display: "flex",
            },
            "& svg": {
                color: "$colors$primary",
                width: "1.25rem",
                height: "1.75rem",
            },
            "& p": {
                margin: "0",
                padding: "0",
            },
        },
    },
};

function mapStateToProps(state) {
    return {
        userAccount: state.authReducer?.userAuth?.userAccount,
        isLoggedIn: state.authReducer?.userAuth?.isLoggedIn,
    };
}

export default connect(mapStateToProps, {
    createSubscription,
    createPayAsYouGo,
})(withRouter(SellPlan));
